<template>
  <div id="nav" @click="hideVisible" v-if="$store.state.mainData.userInfo">
    <div class="app-header">
      <Header @toggleBurgerMenu="toggleBurgerMenu" />
    </div>
    <div class="app-left">
      <Folders
        :burgerMenuIsShow="burgerMenuIsShow"
        @toggleBurgerMenu="toggleBurgerMenu"
      />
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import Header from "@/components/Header.vue";
import Folders from "@/components/Folders.vue";
import { useRouter } from "vue-router";
export default {
  components: {
    Folders,
    Header,
  },
  created() {
    const store = useStore();
    store.dispatch("sendRequestCookies");
    store.dispatch("getUserInfo");
  },
  setup() {
    const store = useStore();
    const router = useRouter({ mode: "history" });
    const burgerMenuIsShow = ref(false); // состояние бургер-меню
    const hideVisible = () => {
      if (store.state.isVisibleReportList)
        store.commit("setVisibleReportList", false);
    };
    const toggleBurgerMenu = () => {
      burgerMenuIsShow.value = !burgerMenuIsShow.value;
    };

    onMounted(() => {
      setInterval(() => {
        store.dispatch("sendRequestCookies");
      }, 210000);
    });
    return { hideVisible, burgerMenuIsShow, toggleBurgerMenu };
  },
};
</script>
<style lang="scss">
.column-content {
  scrollbar-width: none;
}
#app {
  font-family: "Vela Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
@font-face {
  font-family: "Vela Sans";
  src: url("fonts/VelaSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
body {
  margin: 0;
  height: 100%;
  background: #f7f9ff;
  width: 100%;
  overflow: hidden;
}
html,
body {
  scrollbar-width: thin;
}
#nav {
  width: 100%;
  margin: 0 auto;
}
a {
  color: #777c9a;
  text-decoration: none;
  background: #f6f7fb00;
  transition: all 0.3s;
  &.router-link-exact-active {
    color: #777c9a;
    background: #f6f7fb;
    & .folders-list__item-items-element_img {
      fill: #777c9a;
    }
  }
  &:hover {
    background: #f6f7fb;
    & .folders-list__item-items-element_img {
      fill: #777c9a;
    }
  }
}
.app-left {
  display: flex;
}
.pointer {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f7f9ff;
}
::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 9em;
}
::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(120deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(240deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(0deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #f6f8f4;
  display: none;
}
::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(300deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(60deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(180deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #f6f8f4;
  display: none;
}
::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(150deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(270deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #f6f8f4;
  display: none;
}
::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(330deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(90deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #f6f8f4;
  display: none;
}
</style>
