<template>
  <GrayCoverVue
    v-if="burgerMenuIsShow"
    @grayCoverClickHandler="$emit('toggleBurgerMenu')"
  />
  <div class="burgerMenu" :class="{ burgerMenu__active: burgerMenuIsShow }">
    <div class="burgerMenu__wrapper">
      <div class="burgerMenu__logo">
        <img src="../assets/logo.svg" alt="burgerLogo" />
      </div>
      <div class="burgerMenu__title">Объекты</div>
      <div class="burgerMenu__title-icons">
        <img
          @click="$emit('openTree')"
          src="@/assets/open-tree-icon.png"
          title="Открыть все папки"
        />
        <div
          class="burgerMenu__title-checkbox"
          @click="$emit('selectAllCheckBox')"
          ref="checkBoxIcon"
          :class="{
            'burgerMenu__title-checkbox--active': isSelectAllCheckboxes,
          }"
        >
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.49996 5.085L1.76746 3.3525C1.57257 3.15762 1.25678 3.15706 1.06121 3.35125C0.864661 3.54642 0.864099 3.86414 1.05996 4.06L3.49996 6.5L9.14737 0.852581C9.3421 0.657855 9.3421 0.342141 9.14737 0.147415C8.95271 -0.0472464 8.63713 -0.0473209 8.44237 0.147248L3.49996 5.085Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div class="burgerMenu__search">
        <input
          type="text"
          class="burgerMenu__search_input"
          v-model="inputValue"
          @input="$emit('changeInputValue', inputValue)"
          placeholder="Поиск по имени и IMEI"
        />
        <img
          class="burgerMenu__search_img pointer"
          :src="
            require(`@/assets/${
              isActiveSearchIcon ? 'search-big--red' : 'search-big'
            }.svg`)
          "
          alt=""
        />
      </div>
      <div class="burgerMenu-list">
        <div
          class="burgerMenu-list__item"
          :class="{ locked: folder.invoice?.isBlocked }"
          v-for="(folder, index) in newTerminals"
          :key="index"
        >
          <div
            class="burgerMenu-list__folderName"
            :data-nameFolder="folder.name"
          >
            <img
              v-if="
                folder.folders.length ||
                (folder.terminals && folder.terminals.length)
              "
              @click="$emit('showFolderIn', folder.id)"
              :class="{ 'arrov-active': folder.isactive }"
              src="../assets/arrov.svg"
              class="burgerMenu-list__item-first_arrov"
            />
            <router-link
              class="burgerMenu-list__item-first"
              @click="[$emit('folderClickHandler'), $emit('toggleBurgerMenu')]"
              :to="{ name: 'Profile', params: { id: folder.id } }"
            >
              <div class="burgerMenu-list__item-first_folder-wrap">
                <svg
                  :class="{ 'arrov-active-folder': folder.isactive }"
                  class="burgerMenu-list__item-first_folder"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 7.75V12.25C17 15.25 16.2 16 13 16H5C1.8 16 1 15.25 1 12.25V4.75C1 1.75 1.8 1 5 1H6.2C7.4 1 7.664 1.33 8.12 1.9L9.32 3.4C9.624 3.775 9.8 4 10.6 4H13C16.2 4 17 4.75 17 7.75Z"
                    stroke="#777C9A"
                    stroke-width="1.3"
                    stroke-miterlimit="10"
                  />
                </svg>
              </div>

              <div class="burgerMenu-list__item-first_name">
                {{ folder.name }}
              </div>
              <div
                class="burgerMenu-list__lock"
                v-if="folder.invoice?.isBlocked"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#B7BACB"
                  class="bi bi-lock-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                  />
                </svg>
              </div>
            </router-link>
          </div>
          <div v-show="folder.isactive" class="burgerMenu-list__item-items">
            <TreeTerminals
              class="burgerMenu-list__item"
              :model="folder"
              :activeCheckbox="activeCheckbox"
              :activeTerminalCheck="activeTerminalCheck"
              :activeTerminalBack="activeTerminalBack"
              :isOpenedFolders="isOpenedFolders"
              @showTerminal="terminalClick"
              @checkboxClickHandler="checkboxClick"
            />
          </div>
        </div>
      </div>
      <div class="burgerMenu-linksBox">
        <div class="linksBox">
          <ul class="linksBox__list">
            <li class="linksBox__item" @click="redirectTo('TRACK')">
              <div class="linksBox__ico">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 7.50002H20.4938C20.6432 7.49904 20.7894 7.54329 20.9132 7.62695C21.037 7.71061 21.1326 7.82977 21.1875 7.96877L22.5 11.25"
                    stroke="#777C9A"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.625 20.25C18.8676 20.25 19.875 19.2426 19.875 18C19.875 16.7574 18.8676 15.75 17.625 15.75C16.3824 15.75 15.375 16.7574 15.375 18C15.375 19.2426 16.3824 20.25 17.625 20.25Z"
                    stroke="#777C9A"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M6.375 20.25C7.61764 20.25 8.625 19.2426 8.625 18C8.625 16.7574 7.61764 15.75 6.375 15.75C5.13236 15.75 4.125 16.7574 4.125 18C4.125 19.2426 5.13236 20.25 6.375 20.25Z"
                    stroke="#777C9A"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M15.375 18H8.625"
                    stroke="#777C9A"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.125 18H2.25C2.05109 18 1.86032 17.921 1.71967 17.7803C1.57902 17.6397 1.5 17.4489 1.5 17.25V6.75C1.5 6.55109 1.57902 6.36032 1.71967 6.21967C1.86032 6.07902 2.05109 6 2.25 6H16.5V16.05"
                    stroke="#777C9A"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 11.25H22.5V17.25C22.5 17.4489 22.421 17.6397 22.2803 17.7803C22.1397 17.921 21.9489 18 21.75 18H19.875"
                    stroke="#777C9A"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="linksBox__text">Перейти в мониторинг</div>
            </li>
            <li class="linksBox__item" @click="redirectTo('MAIN')">
              <div class="linksBox__ico">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#777C9A"
                  class="bi bi-house"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"
                  />
                </svg>
              </div>
              <div class="linksBox__text">Вернуться на главную</div>
            </li>
          </ul>
          <div class="linksBox__footer">
            <div class="linksBox__userInfo">
              <div class="userInfo">
                <div class="userInfo__ico">
                  <img :src="userInfo.avatarUrl" alt="avatar" />
                </div>
                <div class="userInfo__desc">
                  <div class="userInfo__name">{{ userInfo.login }}</div>
                  <div class="userInfo__corp">{{ userInfo.folder?.name }}</div>
                </div>
              </div>
            </div>
            <div class="linksBox__logout" @click="logoutAutUser">
              <svg
                class="header__logout-button_img"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  class="first-rect"
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="7.5"
                  fill="white"
                />
                <g clip-path="url(#clip0_377_3752)">
                  <path
                    d="M23.3571 25.7042V26.2857C23.3571 26.5888 23.2367 26.8795 23.0224 27.0938C22.8081 27.3082 22.5174 27.4286 22.2143 27.4286H14.2143C13.9112 27.4286 13.6205 27.3082 13.4061 27.0938C13.1918 26.8795 13.0714 26.5888 13.0714 26.2857V13.7143C13.0714 13.4112 13.1918 13.1205 13.4061 12.9062C13.6205 12.6918 13.9112 12.5714 14.2143 12.5714H22.2143C22.5174 12.5714 22.8081 12.6918 23.0224 12.9062C23.2367 13.1205 23.3571 13.4112 23.3571 13.7143V14.5"
                    stroke="#777C9A"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22 20L27 20"
                    stroke="#777C9A"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M25.0714 17.7143L27.3571 20L25.0714 22.2857"
                    stroke="#777C9A"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="7.5"
                  stroke="#E1E3ED"
                />
                <defs>
                  <clipPath id="clip0_377_3752">
                    <rect
                      width="17"
                      height="17"
                      fill="white"
                      transform="translate(11.5 11.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GrayCoverVue from "@/components/UI/coversForModals/GrayCoverVue";
import TreeTerminals from "@/components/TreeTerminals.vue";
import { useLogin } from "@/composition/useLogin.js";
import config from "/config.json";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    burgerMenuIsShow: {
      type: Boolean,
      required: true,
    },
    newTerminals: {
      type: Array,
      required: true,
    },
    activeTerminalBack: {
      type: Object,
      required: true,
    },
    activeCheckbox: {
      type: Object,
      required: true,
    },
    activeTerminalCheck: {
      type: Object,
      required: true,
    },
    isOpenedFolders: {
      type: Boolean,
      required: true,
    },
    isActiveSearchIcon: {
      type: Boolean,
      required: true,
    },
    searchInputValue: {
      type: String,
      required: true,
    },
    isSelectAllCheckboxes: {
      type: Boolean,
      required: true,
    },
  },
  components: { GrayCoverVue, TreeTerminals },
  emits: [
    "showFolderIn",
    "showTerminal",
    "folderClickHandler",
    "changeInputValue",
    "checkboxClickHandler",
    "openTree",
    "selectAllCheckBox",
    "toggleBurgerMenu",
  ],
  setup(props, { emit }) {
    const inputValue = ref("");
    const store = useStore();
    const userInfo = computed(() => store.state.mainData.userInfo);
    const { logoutUser } = useLogin();
    // при изменении инпута у родителя, изменяем значение и у burgerMenu
    watch(
      () => props.searchInputValue,
      () => {
        inputValue.value = props.searchInputValue;
      },
    );
    function redirectTo(redirectPath) {
      switch (redirectPath) {
        case "MAIN":
          window.location.href = config.MAIN_URL;
          break;
        case "TRACK":
          window.location.href = config.TRACK_URL;
          break;
        default:
          window.location.href = config.MAIN_URL;
      }
    }
    const logoutAutUser = () => {
      store.commit("setLogin", false);
      logoutUser(store.state.fetchCookies.params).then(() => {
        store.commit("clearTokenCSRF");
        window.location.href = config.AUTH_URL;
      });
    };
    const checkboxClick = (id, folderId) => {
      emit("checkboxClickHandler", id, folderId);
    };
    const terminalClick = (online, idTerminal, name, icon) => {
      emit("showTerminal", online, idTerminal, name, icon);
      emit("toggleBurgerMenu");
    };
    return {
      inputValue,
      checkboxClick,
      userInfo,
      logoutAutUser,
      redirectTo,
      terminalClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.burgerMenu {
  position: fixed;
  width: fit-content;
  box-sizing: border-box;
  height: 100vh;
  height: 100dvh;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
  padding: 10px 20px;
  transform: translateX(-100%);
  transition: 0.2s all linear;
  border-radius: 0 5px 5px 0;
  &__logo {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #9e9e9e;
    padding-bottom: 10px;
    img {
      width: auto;
      height: 30px;
    }
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  &__title {
    color: #35384a;
    font-size: 18px;
    font-weight: 500;
    &-icons {
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
    &-checkbox {
      height: 24px;
      width: 24px;
      background-color: #fff;
      border: 1px solid black;
      border-radius: 5px;
      position: relative;
      svg {
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__search {
    position: relative;
    display: flex;
    margin-top: 5px;
    &_input {
      background: #ffffff;
      border: 1px solid #9e9e9e;
      border-radius: 8px;
      padding: 10px 40px 10px 10px;
      width: 210px;
      font-size: 14px;
      color: #35384a;
    }
    &_img {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      margin-left: -30px;
      width: 15px;
      height: 15px;
    }
  }
  &-linksBox {
    width: 100%;
  }
  .arrov-active {
    transform: rotate(0deg);
  }
}
.burgerMenu__title-checkbox--active {
  background-color: #ff0000;
  border: 1px solid #ff0000;
}
.burgerMenu-list {
  width: 270px;
  height: calc(100vh - 315px);
  height: calc(100dvh - 315px);
  padding: 5px 0;
  overflow-y: auto;
  min-height: 220px;
  transition: all 0.3s;
  color: #777c9a;
  border-bottom: 1px solid #9e9e9e;
  &__item {
    color: inherit;
    a {
      color: inherit;
    }
    &.locked {
      color: #b7bacb;
    }
    li {
      position: relative;
      list-style: none;
      border-left: 1px solid #e1e3ed;
    }
  }
  &__item-itemsInfo {
    width: 100%;
    & > div {
      width: 100%;
      & > div {
        width: 100%;
        & > a {
          width: 100%;
        }
      }
    }
  }
  &__lock {
    padding-left: 5px;
    margin-right: 0;
    margin-left: auto;
  }
  &__folderName {
    display: flex;
  }
  &__item-first {
    display: flex;
    padding: 10px 20px;
    background: none;
    cursor: pointer;
    transition: all 0.3s;
    align-items: center;
    width: 100%;
    &_name {
      text-align: left;
      padding-left: 32px;
      font-weight: 500;
      font-size: 16px;
    }
    &_arrov {
      transform: rotate(-90deg);
      transition: all 0.3s;
      width: 9px;
      padding: 10px;
      z-index: 7;
      margin-right: -30px;
      cursor: pointer;
    }
    &_folder-wrap {
      width: 18px;
      height: 17px;
    }
    &_folder {
      margin-left: 15px;
      fill: #00000000;
      transition: all 0.3s;
      width: 100%;
      height: 100%;
    }
  }
  &__item-second {
    display: flex;
    padding: 10px 0px;
    background: none;
    cursor: pointer;
    transition: all 0.3s;
    margin-left: 0px;
    &_name {
      margin-left: 14px;
      color: #777c9a;
      font-weight: 500;
      font-size: 16px;
    }
    &_arrov {
      transform: rotate(-90deg);
      transition: all 0.3s;
      width: 9px;
      padding: 10px;
      z-index: 7;
    }
    &_folder {
      margin-left: 20px;
      fill: red;
    }
  }
  &__item-items {
    margin-left: 15px;
  }
  &__item-items-element-wrap {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 10px;
    background: none;
    cursor: pointer;
    transition: all 0.3s;
    background: #ffffff00;
    text-align: left;
    justify-content: space-between;
  }
  &__item-items-element {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 10px;
    background: none;
    cursor: pointer;
    transition: all 0.3s;
    background: #ffffff00;
    text-align: left;
    justify-content: space-between;
    &_network {
      width: 5px;
      height: 5px;
      background: red;
      border-radius: 100%;
      z-index: 3;
      margin-right: 5px;
    }
    &_img {
      z-index: 2;
      margin-left: 15px;
    }
    &_name {
      margin: 0;
      margin-left: 10px;
      padding: 10px 0px;
      width: 170px;
    }
    &_select {
      background: #ffffff;
      border: 1px solid #9e9e9e;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 6px;
      transition: all 0.3s;
      margin-left: -50px;
      position: absolute;
      right: 3px;
      cursor: pointer;
    }
  }
  & ul {
    padding-left: 16px;
  }
}
.burgerMenu__active {
  transform: translateX(0);
  overflow-y: hidden;
}
.linksBox {
  width: 100%;
  &__list {
    padding: 0;
  }
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
  }
  &__ico {
    width: 25px;
    height: 25px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__text {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: #777c9a;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
  &__logout {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.userInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 10px;
  text-align: left;
  &__ico {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  &__name {
    font-weight: 700;
    font-size: 16px;
  }
  &__corp {
    color: #777c9a;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 1024px) {
  .burgerMenu {
    &__title {
      font-size: 16px;
      &-icons {
        img {
          width: 28px;
          height: 28px;
        }
      }
      &-checkbox {
        height: 20px;
        width: 20px;
      }
    }
    &__search {
      width: 100%;
      margin-bottom: 10px;
      &_input {
        padding: 5px 30px 5px 5px;
        width: 100%;
      }
      &_img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .burgerMenu-list {
    &__item-first {
      padding: 5px 15px;
      &_folder-wrap {
        width: 15px;
        height: auto;
      }
    }
  }
  .linksBox {
    padding-top: 10px;
    &__text {
      font-size: 14px;
    }
    &__ico {
      width: 20px;
      height: 20px;
    }
  }
  .userInfo {
    &__name {
      font-size: 14px;
    }
  }
}
@media (max-width: 768px) {
  .burgerMenu {
    &__logo {
      padding: 5px 0 15px 0;
    }
    &__title {
      padding: 5px 0;
    }
    &__search {
      margin-top: 10px;
      &_input {
        padding: 8px 30px 8px 8px;
      }
    }
    &-list {
      height: calc(100vh - 410px);
      height: calc(100dvh - 410px);
    }
  }
  .linksBox__footer {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 20px;
  }
}
@media (max-width: 480px) {
  .burgerMenu {
    &-list {
      height: calc(100vh - 350px);
      height: calc(100dvh - 350px);
    }
  }
}
</style>
