import { createStore } from "vuex";
import fetchCookies from "@/store/fetchCookies";
import pagination from "@/store/pagination";
import mainData from "@/store/mainData";
export default createStore({
  state: () => ({
    isVisibleActivePie: false,

    isVisibleReportList: false,
    report: null,
    isReport: false,
    isEmptyCards: true,

    heightMainListBlock: `calc(${100}vh - ${380}px)`,
    heightMainCardsBlock: `calc(${100}vh - ${140}px)`,

    idTerminal: null,
    isModalCalculator: false,
    reportData: null,
    inputLiterValue: null,
    isActiveUpdate: false,

    // данные "Отчет по топливораздач - Водители"
    reportDataSelect: null,
    isVisibleReportSelect: false,
  }),
  mutations: {
    setVisibleReportSelect(state, bool) {
      state.isVisibleReportSelect = bool;
    },
    setVisibleReportSelectByFuelCards(state, bool) {
      state.isVisibleReportSelectByFuelCards = bool;
    },
    setReportDataSelect(state, data) {
      state.reportDataSelect = data;
    },
    setVisibleReportList(state, bool) {
      state.isVisibleReportList = bool;
    },
    setReportData(state, data) {
      data.calculator = data.map(item => {
        item.calc = 0;
        return item;
      });
      state.reportData = data;
    },
    setIsEmptyCards(state, bool) {
      state.isEmptyCards = bool;
    },
    setIsVisibleActivePie(state, bool) {
      state.isVisibleActivePie = bool;
    },
    setReport(state, data) {
      state.report = data;
    },
    setIsReport(state, bool) {
      state.isReport = bool;
    },
    setHeightMainListBlock(state, num) {
      if (num > 185) state.heightMainListBlock = "auto";
      else state.heightMainListBlock = `calc(${100}vh - ${349 + (num - 9)}px)`;
    },
    setHeightMainCardsBlock(state, num) {
      if (num > 185) state.heightMainCardsBlock = "auto";
      else
        state.heightMainCardsBlock = `calc(${100}vh - ${190 + (num - 85)}px)`;
    },
    setIdTerminal(state, id) {
      state.idTerminal = id;
    },
    setModalCalculator(state, bool) {
      state.isModalCalculator = bool;
    },
    setInputLiter(state, number) {
      state.inputLiterValue = number;
    },
    setActiveUpdate(state, bool) {
      state.isActiveUpdate = bool;
    },
  },
  modules: {
    fetchCookies,
    pagination,
    mainData,
  },
});
