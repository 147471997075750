<template>
  <div class="folders">
    <div class="folders__wrapper">
      <div class="folders__title">Объекты</div>
      <div class="folders__title-icons">
        <img
          @click="openTree()"
          src="@/assets/open-tree-icon.png"
          style="height: 32px; width: 32px; cursor: pointer"
          title="Открыть все папки"
        />
        <div
          class="folders__title-checkbox"
          @click="selectAllCheckBox()"
          ref="checkBoxIcon"
          :class="{ 'folders__title-checkbox--active': isSelectAllCheckboxes }"
        >
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.49996 5.085L1.76746 3.3525C1.57257 3.15762 1.25678 3.15706 1.06121 3.35125C0.864661 3.54642 0.864099 3.86414 1.05996 4.06L3.49996 6.5L9.14737 0.852581C9.3421 0.657855 9.3421 0.342141 9.14737 0.147415C8.95271 -0.0472464 8.63713 -0.0473209 8.44237 0.147248L3.49996 5.085Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div class="folders__search">
        <input
          type="text"
          class="folders__search_input"
          v-model="name"
          @input="getValueSearch(name)"
          placeholder="Поиск по имени и IMEI"
        />
        <img
          class="folders__search_img pointer"
          :src="
            require(`@/assets/${
              isActiveSearchIcon ? 'search-big--red' : 'search-big'
            }.svg`)
          "
          alt=""
        />
      </div>
      <div
        class="folders-list"
        :style="{
          height:
            !terminalInfo || !isVisibility
              ? ' calc(100vh - 293px)'
              : 'calc(100vh - 460px)',
        }"
      >
        <div
          class="folders-list__item"
          :class="{ locked: folder.invoice?.isBlocked }"
          v-for="(folder, index) in newTerminals"
          :key="index"
        >
          <div class="folders-list__folderName" :data-nameFolder="folder.name">
            <img
              v-if="
                folder.folders.length ||
                (folder.terminals && folder.terminals.length)
              "
              @click="showFolderIn(folder.id)"
              :class="{ 'arrov-active': folder.isactive }"
              src="../assets/arrov.svg"
              class="folders-list__item-first_arrov"
            />
            <router-link
              class="folders-list__item-first"
              @click="folderClickHandler"
              :to="{ name: 'Profile', params: { id: folder.id } }"
            >
              <div class="folders-list__item-first_folder-wrap">
                <svg
                  :class="{ 'arrov-active-folder': folder.isactive }"
                  class="folders-list__item-first_folder"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 7.75V12.25C17 15.25 16.2 16 13 16H5C1.8 16 1 15.25 1 12.25V4.75C1 1.75 1.8 1 5 1H6.2C7.4 1 7.664 1.33 8.12 1.9L9.32 3.4C9.624 3.775 9.8 4 10.6 4H13C16.2 4 17 4.75 17 7.75Z"
                    stroke="#777C9A"
                    stroke-width="1.3"
                    stroke-miterlimit="10"
                  />
                </svg>
              </div>

              <div class="folders-list__item-first_name" :title="folder.name">
                {{ formatName(folder.name) }}
              </div>
              <div class="folders-list__lock" v-if="folder.invoice?.isBlocked">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#B7BACB"
                  class="bi bi-lock-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                  />
                </svg>
              </div>
            </router-link>
          </div>
          <div v-show="folder.isactive" class="folders-list__item-items">
            <TreeTerminals
              class="folders-list__item"
              :model="folder"
              :activeCheckbox="activeCheckbox"
              :activeTerminalCheck="activeTerminalCheck"
              :activeTerminalBack="activeTerminalBack"
              @showTerminal="showTerminal"
              :isOpenedFolders="isOpenedFolders"
              @checkboxClickHandler="checkboxClickHandler"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="folders-card" v-if="terminalInfo && isVisibility">
      <div class="folders-card-body" v-if="isVisibility">
        <div class="folders-card-body__item" v-if="terminalInfo">
          <div
            class="folders-card-body__item_name"
            style="display: flex; align-items: center"
          >
            Статус
          </div>
          <div
            class="folders-card-body__item_value"
            style="
              background: #a9e5c6;
              color: #2b8f5a;
              padding: 5px 20px;
              border-radius: 8px;
            "
            v-if="terminalInfo.data.isOnline"
          >
            Онлайн
          </div>
          <div
            class="folders-card-body__item_value"
            style="
              background: #fae5e6;
              color: #da2e35;
              padding: 5px 20px;
              border-radius: 8px;
            "
            v-else
          >
            Оффлайн
          </div>
        </div>
        <div class="folders-card-body__item">
          <div class="folders-card-body__item_name">Общий объем топлива</div>
          <div class="folders-card-body__item_value">
            {{ terminalInfo.dutSum }}
            Л.
          </div>
        </div>
        <div class="folders-card-body__item">
          <div class="folders-card-body__item_name">Выдано за сегодня</div>
          <div class="folders-card-body__item_value">
            {{ terminalInfo.data.fuelIssued }}
          </div>
        </div>
        <div
          class="folders-card-body__item"
          v-for="(duts, i) in terminalInfo.dutTemp"
          :key="i"
        >
          <div class="folders-card-body__item_name">
            Температура в ёмкости {{ duts.name }}
          </div>
          <div class="folders-card-body__item_value">{{ duts.t }}°</div>
        </div>
      </div>
    </div>
  </div>
  <BurgerMenu
    v-if="newTerminals"
    :burgerMenuIsShow="burgerMenuIsShow"
    @checkboxClickHandler="checkboxClickHandler"
    @showFolderIn="showFolderIn"
    :newTerminals="newTerminals"
    :isSelectAllCheckboxes="isSelectAllCheckboxes"
    @folderClickHandler="folderClickHandler"
    @toggleBurgerMenu="$emit('toggleBurgerMenu')"
    :isActiveSearchIcon="isActiveSearchIcon"
    :searchInputValue="name"
    @changeInputValue="getValueSearch"
    @openTree="openTree"
    @selectAllCheckBox="selectAllCheckBox"
    :activeTerminalBack="activeTerminalBack"
    :activeCheckbox="activeCheckbox"
    :activeTerminalCheck="activeTerminalCheck"
    :isOpenedFolders="isOpenedFolders"
    @showTerminal="showTerminal"
  />
</template>
<script>
import { useStore } from "vuex";
import { ref, getCurrentInstance, watchEffect } from "vue";
import { useFolders } from "@/composition/useFolders.js";
import { useRouter, useRoute } from "vue-router";
import TreeTerminals from "@/components/TreeTerminals.vue";
import BurgerMenu from "@/components/BurgerMenu.vue";
export default {
  components: { TreeTerminals, BurgerMenu },
  props: {
    burgerMenuIsShow: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["toggleBurgerMenu"],
  setup(props, { emit }) {
    const store = useStore();
    const app = ref(getCurrentInstance());
    const router = useRouter();
    const route = useRoute();
    const showSecond = ref([]);
    const showSecondItems = ref(false);
    const active = ref(false);
    const name = ref(""); // поле поиска
    const { getFolders, folders } = useFolders();
    const activeTerminalCheck = ref([]);
    const activeCheckbox = ref([]);
    const isVisibility = ref(false);
    const newTerminals = ref(); // массив терминалов
    const isOpenedFolders = ref(false);
    const isActiveSearchIcon = ref(false); // красим searchIcon или нет
    const checkBoxIcon = ref(null); // чекбокс "Выделить все"
    const isSelectAllCheckboxes = ref(false); //состояние чекбокса "Выделить все"
    const hideInfoBlock = () => {
      const arrActiveTerminals = activeTerminalCheck.value.filter(
        el => el.active === true,
      );
      arrActiveTerminals.length > 1
        ? (isVisibility.value = false)
        : (isVisibility.value = true);
    };
    // клик по папке
    const folderClickHandler = () => {
      isVisibility.value = false;
      store.commit("setIsVisibleActivePie", false);
    };
    // клик по чекбоксу
    const checkboxClickHandler = (id, folderId) => {
      let checkBoxs = document.querySelectorAll("[data-terminalid]");
      let allCheckboxesState = 0; // кол-во чекнутых флажков
      checkBoxs.forEach(checkBox => {
        if (checkBox.dataset.terminalid == id)
          checkBox.classList.toggle("red-checkbox");
        if (checkBox.classList.contains("red-checkbox")) {
          app.value.appContext.config.globalProperties.$terminal_id.push(id);
          allCheckboxesState += 1;
        } else {
          app.value.appContext.config.globalProperties.$terminal_id =
            app.value.appContext.config.globalProperties.$terminal_id.filter(
              el => el != id,
            );
        }
      });
      // если все чекбоксы активны, включаем флажок "Выделить все"
      isSelectAllCheckboxes.value = allCheckboxesState === checkBoxs.length;

      activeTerminalCheck.value.forEach(item => {
        if (
          item.terminal.id == id &&
          item.id_folder == folderId &&
          item.active == false
        ) {
          app.value.appContext.config.globalProperties.$terminal_id.push(id);
          item.active = true;
          item.terminalId = id;
          if (route.params.rd) hideInfoBlock();
          return;
        }
        if (
          item.terminal.id == id &&
          item.id_folder == folderId &&
          item.active == true
        ) {
          item.active = false;
          app.value.appContext.config.globalProperties.$terminal_id =
            app.value.appContext.config.globalProperties.$terminal_id.filter(
              el => el != id,
            );
          if (route.params.rd) hideInfoBlock();
        }
      });
      app.value.appContext.config.globalProperties.$terminal_id = Array.from(
        new Set(app.value.appContext.config.globalProperties.$terminal_id),
      );
      activeCheckbox.value =
        app.value.appContext.config.globalProperties.$terminal_id;
    };
    function requestFolders(params) {
      getFolders(params).then(() => {
        newTerminals.value = folders.value;
        localStorage.setItem("foldersArr", JSON.stringify(folders.value));

        function allFolders(arr) {
          let arrayAllFolderss = [];
          arr.forEach(item => {
            if (item.terminals && item.terminals.length)
              item.terminals.forEach(terminal => (terminal.folderId = item.id));
            if (item.folders && item.folders.length) {
              arrayAllFolderss.push(item);
              arrayAllFolderss = [
                ...arrayAllFolderss,
                ...allFolders(item.folders),
              ];
            } else arrayAllFolderss.push(item);
          });

          return arrayAllFolderss;
        }

        if (folders.value.length) folders.value = allFolders(folders.value);
        showSecond.value = folders.value;
        for (let i = 0; i < folders.value.length; i++) {
          for (let j = 0; j < folders.value[i].terminals.length; j++) {
            activeTerminalCheck.value.push({
              terminal: folders.value[i].terminals[j],
              id_folder: folders.value[i].id,
              active: false,
            });
            activeTerminalBack.value.push({
              terminal: folders.value[i].terminals[j],
              id_folder: folders.value[i].id,
              active: false,
            });
          }
          if (route.params.id == folders.value[i].id)
            showSecond.value[i].isactive = true;
        }
      });
    }

    const isTokenCsrf = ref(true);
    const terminalInfo = ref();
    const updateParams = () => {
      setInterval(() => {
        terminalInfo.value =
          app.value.appContext.config.globalProperties.$globalObject.terminal;
      }, 2000);
    };
    watchEffect(() => {
      if (store.state.fetchCookies.tokenCSRF) {
        if (isTokenCsrf.value) {
          requestFolders(store.state.fetchCookies.params);
        }
        isTokenCsrf.value = false;
      }
    });
    const checkFolder = id => {
      router.push({
        name: "Profile",
        params: {
          id: id,
        },
      });
    };
    // открыть содержимое папки по id
    const showFolderIn = id => {
      newTerminals.value.forEach(item => {
        if (id == item.id) {
          !item.isactive ? (item.isactive = true) : (item.isactive = false);
        }
      });
    };

    const activeTerminalBack = ref([]);
    const status = ref();
    const showTerminal = (online, idTerminal, name, icon) => {
      store.commit("setIsVisibleActivePie", false);
      store.commit("setPreloaderGraphs", true);
      store.commit("setTitleTerminal", name);
      store.commit("setTerminalIcon", [icon, online]);
      store.commit("setIdTerminal", idTerminal);
      app.value.appContext.config.globalProperties.$globalObject.idTerminal =
        idTerminal;
      updateParams();
      hideInfoBlock();
      active.value = true;
      status.value = online;
    };
    // функция открытия/закрытия всех папок
    function activeFolders(arr, bool) {
      arr.forEach(item => {
        if (item.folders && item.folders.length) {
          item.isactive = bool;
          activeFolders(item.folders, bool);
        } else {
          item.isactive = bool;
        }
      });
    }
    //логика поиска
    const getValueSearch = value => {
      name.value = value;
      if (!value) {
        newTerminals.value = folders.value;
        isActiveSearchIcon.value = false;
      } else {
        isActiveSearchIcon.value = true;
        const filteredSearchArr = JSON.parse(JSON.stringify(folders.value));
        const filteredArr = filteredSearchArr.filter(elem => {
          const condition1 = elem.name
            .toLowerCase()
            .includes(name.value.toLowerCase());
          // если есть совпадение в имени папки, возвращ. все элем папки
          if (condition1) return condition1; // это условие приоритетнее
          else {
            // иначе проходимся по imei и name обьектов и возвращаем их отфильтрованными
            const filteredObj = elem.terminals?.filter(
              obj =>
                obj.name.toLowerCase().includes(name.value.toLowerCase()) ||
                obj.imei.toLowerCase().includes(name.value.toLowerCase()),
            );
            const condition2 = filteredObj?.length;
            elem.terminals = filteredObj;
            // открываем содержимое папки
            elem.isactive = true;
            return condition2;
          }
        });
        newTerminals.value = filteredArr;
      }
    };

    const activeAllCheckboxTerminals = action => {
      setTimeout(() => {
        let checkBoxs = document.querySelectorAll(
          ".folders-list__item-items-element_select",
        );
        checkBoxs.forEach(checkBox => {
          if (action == "remove") checkBox.classList.remove("red-checkbox");
          else checkBox.classList.add("red-checkbox");
        });
      }, 0);
    };
    const formatName = v => (v?.length >= 17 ? `${v.slice(0, 17)}...` : v);
    const selectAllCheckBox = () => {
      if (route.params.rd)
        checkBoxIcon.value.classList.contains("folders__title-checkbox--active")
          ? (isVisibility.value = false)
          : (isVisibility.value = true);
      isOpenedFolders.value = false;

      openTree();

      if (!isSelectAllCheckboxes.value) {
        activeTerminalCheck.value.forEach(e => {
          e.active = true;
          app.value.appContext.config.globalProperties.$terminal_id.push(
            e.terminal.id,
          );
        });
        isSelectAllCheckboxes.value = true;

        activeAllCheckboxTerminals("add");
      } else {
        activeTerminalCheck.value.forEach(e => (e.active = false));
        app.value.appContext.config.globalProperties.$terminal_id.length = 0;
        isSelectAllCheckboxes.value = false;

        activeAllCheckboxTerminals("remove");
      }
    };
    const openTree = () => {
      if (!isOpenedFolders.value) {
        showSecond.value.forEach(e => (e.isactive = true));
        isOpenedFolders.value = true;
      } else {
        showSecond.value.forEach(e => (e.isactive = false));
        isOpenedFolders.value = false;
      }
    };
    return {
      name,
      activeFolders,
      formatName,
      activeCheckbox,
      folderClickHandler,
      isActiveSearchIcon,
      isOpenedFolders,
      isVisibility,
      showSecond,
      showSecondItems,
      folders,
      checkFolder,
      showTerminal,
      openTree,
      getValueSearch,
      newTerminals,
      isTokenCsrf,
      activeTerminalCheck,
      checkboxClickHandler,
      isSelectAllCheckboxes,
      app,
      status,
      activeTerminalBack,
      showFolderIn,
      terminalInfo,
      selectAllCheckBox,
      checkBoxIcon,
    };
  },
};
</script>
<style lang="scss" src="../styles/folders.scss"></style>
