import { createApp, inject } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import VueTheMask from "vue-the-mask";
import ScrollPicker from "vue3-scroll-picker";
import excel from "vue-excel-export";
import "@vuepic/vue-datepicker/dist/main.css";

const app = createApp(App);
app.component("Datepicker", Datepicker);
app.config.globalProperties.$globalObject = {};
app.config.globalProperties.$report = null;
app.config.globalProperties.$card_id = [];
app.config.globalProperties.$terminal_id = [];
app.config.globalProperties.$toggles = [];
app
  .use(store)
  .use(VueTheMask)
  .use(ScrollPicker)
  .use(router)
  .use(excel)
  .mount("#app");
