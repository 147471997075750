import { ref } from "vue";
import { useApi } from "@/composition/useApi.js";
export function useFolders() {
  const { api } = useApi();
  const folders = ref();
  const getFolders = () => {
    return api.get("/folders?terminalType=fuel").then(r => {
      // фильтруем и оставляем только непустые папки
      folders.value = r.data.filter(
        itm => itm.terminals && itm.terminals.length,
      );
    });
  };
  return {
    getFolders,
    folders,
  };
}
