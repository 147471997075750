import { ref } from "vue";

import { useApi } from "@/composition/useApi.js";
export function useReport() {
  const { api } = useApi();
  const report = ref();
  const getReportCards = (cards, timestart, timeend, params, body) => {
    return api
      .get(
        `/reports/cards/fuel/distribution?ids=${cards}&from=${timestart}&to=${timeend}&minDiscrepancy=${body.minDiscrepancy}&isOnlyDiscrepancy=${body.isOnlyDiscrepancy}`,
      )
      .then(r => {
        report.value = r.data.report;
        report.value.table.body = report.value.table.body
          .map(v => [v.header, ...v.body, v.footer])
          .flat(1);
      });
  };
  const getReportFuelCards = (timestart, timeend, params, body) => {
    return api
      .get(
        `/reports/terminals/fuel/cards?from=${timestart}&to=${timeend}&minDiscrepancy=${body.minDiscrepancy}&isOnlyDiscrepancy=${body.isOnlyDiscrepancy}`,
      )
      .then(r => {
        report.value = r.data.report;
        report.value.table.body = report.value.table.body
          .map(v => [v.header, ...v.body, v.footer])
          .flat(1);
      });
  };
  const getReportBalance = (cards, timestart, timeend) => {
    return api
      .get(
        `/reports/cards/balance/history?ids=${cards}&from=${timestart}&to=${timeend}`,
      )
      .then(r => {
        report.value = r.data.report;
        report.value.table.body = report.value.table.body
          .map(v => [v.header, ...v.body, v.footer])
          .flat(1);
      });
  };
  const getReportTerminalFuel = (cards, timestart, timeend) => {
    return api
      .get(
        `/reports/terminals/fuel/distribution?ids=${cards}&from=${timestart}&to=${timeend}`,
      )
      .then(r => {
        report.value = r.data.report;
        report.value.table.body = report.value.table.body
          .map(v => [v.header, ...v.body, v.footer])
          .flat(1);
      });
  };
  const getReportTerminalInFuel = (cards, timestart, timeend) => {
    return api
      .get(
        `/reports/terminals/fuel/refill?ids=${cards}&from=${timestart}&to=${timeend}`,
      )
      .then(r => {
        report.value = r.data.report;
        report.value.table.body = report.value.table.body
          .map(v => [v.header, ...v.body, v.footer])
          .flat(1);
      });
  };
  const getReportTerminalOutFuel = (cards, timestart, timeend) => {
    return api
      .get(
        `/reports/terminals/fuel?ids=${cards}&from=${timestart}&to=${timeend}`,
      )
      .then(r => {
        report.value = r.data.report;
        report.value.table.body = report.value.table.body
          .map(v => [v.header, ...v.body, v.footer])
          .flat(1);
      });
  };

  return {
    report,
    getReportCards,
    getReportFuelCards,
    getReportBalance,
    getReportTerminalFuel,
    getReportTerminalInFuel,
    getReportTerminalOutFuel,
  };
}
