<template>
  <span class="hovertooltip" v-text="text"></span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.hovertooltip {
  visibility: hidden;
  width: fit-content;
  padding: 5px;
  white-space: nowrap;
  background: #333540;
  box-shadow: 0px 8px 8px rgba(47, 50, 66, 0.04),
    0px 12px 28px rgba(47, 50, 66, 0.12);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -10px;
}
.hovertooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333540 transparent;
}
</style>
