import { useApi } from "@/composition/useApi.js";
export function useLogin() {
  const { api } = useApi();

  const logoutUser = () => {
    return api.post("/auth/logout", null).then(() => {
      localStorage.removeItem("scrf");
      localStorage.removeItem("foldersArr");
    });
  };

  return {
    logoutUser,
  };
}
