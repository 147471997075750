<template>
  <li>
    <div v-for="(folder, idx) in model.folders" :key="idx">
      <div
        class="folders-list__folderName"
        style="display: flex"
        :data-nameFolder="folder.name"
      >
        <img
          class="folders-list__item-first_arrov"
          v-if="
            folder.folders.length ||
            (folder.terminals && folder.terminals.length)
          "
          :class="{ 'arrov-active': folder.isactive }"
          src="../assets/arrov.svg"
          @click.stop="showFolderIn(folder.id)"
        />
        <router-link
          class="folders-list__item-first"
          :to="{ name: 'Profile', params: { id: folder.id } }"
        >
          <div class="folders-list__item-first_folder-wrap">
            <svg
              class="folders-list__item-first_folder"
              :class="{ 'arrov-active-folder': folder.isactive }"
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 7.75V12.25C17 15.25 16.2 16 13 16H5C1.8 16 1 15.25 1 12.25V4.75C1 1.75 1.8 1 5 1H6.2C7.4 1 7.664 1.33 8.12 1.9L9.32 3.4C9.624 3.775 9.8 4 10.6 4H13C16.2 4 17 4.75 17 7.75Z"
                stroke="#777C9A"
                stroke-width="1.3"
                stroke-miterlimit="10"
              />
            </svg>
          </div>

          <div class="folders-list__item-first_name" :title="folder.name">
            {{ formatName(folder.name) }}
          </div>
        </router-link>
      </div>
      <ul v-show="folder.isactive">
        <TreeTerminals
          class="folders-list__item"
          :model="folder"
          :activeCheckbox="activeCheckbox"
          :activeTerminalCheck="activeTerminalCheck"
          :activeTerminalBack="activeTerminalBack"
          @showTerminal="showTerminal"
          @checkboxClickHandler="checkboxClickHandler"
          :isOpenedFolders="isOpenedFolders"
        >
        </TreeTerminals>
      </ul>
    </div>
    <div
      class="folders-list__terminalName"
      style="display: flex"
      v-for="(terminal, jindex) in model.terminals"
      :key="jindex"
    >
      <div style="display: flex; align-items: center">
        <router-link
          class="folders-list__item-items-element"
          :to="`/profile/${terminal.folderId}/terminal/${terminal.id}/`"
          :class="{
            'terminal-select': activeTerminalBack[jindex].active,
          }"
        >
          <div
            class="folders-list__item-items-element-wrap"
            @click="
              showTerminal(
                terminal.isOnline,
                terminal.id,
                terminal.name,
                terminal.terminalGroup.icon.toLowerCase(),
              )
            "
          >
            <div style="display: flex; align-items: center">
              <div
                class="folders-list__item-items-element_network"
                v-if="!terminal.isOnline"
              ></div>
              <div
                class="folders-list__item-items-element_network green-dot"
                v-else
              ></div>
              <div
                class="folders-list__item-items-element_imgFuel"
                v-if="terminal.terminalGroup.icon == 'FUEL'"
              >
                <img :src="require(`@/assets/fuel.svg`)" alt="fuel" />
              </div>
              <div class="folders-list__item-items-element_imgRefueller" v-else>
                <img :src="require(`@/assets/car.svg`)" alt="refueller" />
              </div>
            </div>
            <p
              class="folders-list__item-items-element_name"
              :title="terminal.name"
            >
              {{ formatName(terminal.name) }}
            </p>
          </div>
        </router-link>

        <div
          class="folders-list__item-items-element_select"
          :class="{ 'red-checkbox': activeCheckbox.includes(terminal.id) }"
          :data-terminalid="terminal.id"
          @click.stop="checkboxClickHandler(terminal.id, terminal.folderId)"
        >
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.49996 5.085L1.76746 3.3525C1.57257 3.15762 1.25678 3.15706 1.06121 3.35125C0.864661 3.54642 0.864099 3.86414 1.05996 4.06L3.49996 6.5L9.14737 0.852581C9.3421 0.657855 9.3421 0.342141 9.14737 0.147415C8.95271 -0.0472464 8.63713 -0.0473209 8.44237 0.147248L3.49996 5.085Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import { ref, onMounted } from "@vue/runtime-core";
export default {
  name: "TreeTerminals",
  props: {
    model: Object,
    generaid: Boolean,
    isOpenedFolders: Boolean,
    activeTerminalBack: Array,
    activeTerminalCheck: Array,
    activeCheckbox: Array,
  },
  emits: ["showTerminal", "checkboxClickHandler"],
  setup(props, { emit }) {
    const arrayFolders = ref(null);
    const showFolderIn = folderId => {
      arrayFolders.value = props.model.folders;
      arrayFolders.value.forEach(item => {
        if (folderId == item.id) {
          !item.isactive ? (item.isactive = true) : (item.isactive = false);
        }
      });
    };
    const showTerminal = (isOnline, id, name, icon) => {
      emit("showTerminal", isOnline, id, name, icon);
    };
    const formatName = v => (v?.length >= 15 ? `${v.slice(0, 15)}...` : v);

    const checkboxClickHandler = (id, folderId) => {
      emit("checkboxClickHandler", id, folderId);
    };

    onMounted(() => {
      arrayFolders.value = props.model.folders;
    });

    return {
      arrayFolders,
      showFolderIn,
      showTerminal,
      checkboxClickHandler,
      formatName,
    };
  },
};
</script>
<style lang="scss"></style>
