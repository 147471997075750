export default {
  state: () => ({
    limit: 20,
    page: 1,
    totalPages: 0,
    start: 1,
    end: null,
    postsOnePage: [],
    paginationPages: [],
  }),
  mutations: {
    setStart(state) {
      state.start = (state.page - 1) * state.limit;
    },
    setEnd(state) {
      state.end = state.start + state.limit;
    },
    setPostsOnePage(state, data) {
      state.postsOnePage = data.slice(state.start, state.end);
    },
    editCardOnePage(state, data) {
      state.postsOnePage = state.postsOnePage.map(card =>
        card.id === data.id ? data : card,
      );
    },
    setPage(state, page) {
      state.page = page;
    },
    pageUp(state) {
      state.page = state.page + 1;
    },
    pageDown(state) {
      state.page = state.page - 1;
    },
    setTotalPages(state, totalPages) {
      state.totalPages = totalPages;
    },

    setActivePage(state, data) {
      state.paginationPages = data[0].slice(
        state.page - data[1],
        state.page - data[1] + 7,
      );
    },
    setActivePageEnd(state, data) {
      if (data.length >= 7 && state.page - 4 > 0)
        state.paginationPages = data.slice(
          state.totalPages - 7,
          state.totalPages,
        );
      else if (data.length < 7 && state.page - 4 > 0)
        state.paginationPages = data.slice(0, state.totalPages);
      else if (data.length >= 7 && state.page - 4 <= 0)
        state.paginationPages = data.slice(0, 7);
      else state.paginationPages = data.slice(0, data.length);
    },
  },
};
