<template>
  <div class="header">
    <div class="header__wrapper">
      <GrayCover v-if="teleportCenter && dpIsOpened" @click.stop />
      <div class="header__logo">
        <img src="../assets/logo.svg" class="header__header__logo_img" />
      </div>
      <div class="header__burger" @click="$emit('toggleBurgerMenu')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#777C9A"
          class="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </div>
      <div class="header__date">
        <div class="header__date-begin">
          <Datepicker
            @open="openDatePicker"
            @closed="closedDatePicker"
            selectText="Выбрать"
            cancelText="Отмена"
            class="datePicker-one"
            placeholder="ДД.ММ.ГГГГ ЧЧ:ММ"
            v-model="dateB"
            lang="ru"
            autocomplete="off"
            :locale="'ru'"
            format="dd.MM.yyyy HH:mm"
            :time-picker-component="timePicker"
            :teleport-center="teleportCenter"
            textInput
            monthNameFormat="long"
          />
        </div>
        <div class="header__date-finish">
          <Datepicker
            @open="openDatePicker"
            @closed="closedDatePicker"
            selectText="Выбрать"
            cancelText="Отмена"
            class="datePicker-two"
            placeholder="ДД.ММ.ГГГГ ЧЧ:ММ"
            v-model="dateF"
            lang="ru"
            autocomplete="off"
            :locale="'ru'"
            format="dd.MM.yyyy HH:mm"
            :time-picker-component="timePicker"
            :teleport-center="teleportCenter"
            textInput
            monthNameFormat="long"
          />
        </div>
        <div class="header__doc">
          <div class="header__doc_button pointer" @click.stop="reportCard">
            Составить отчет
          </div>
          <div class="header__doc-list" v-if="$store.state.isVisibleReportList">
            <div class="header__doc-list_item" @click="openReportSelect">
              Отчёт по топливораздаче - водители
            </div>
            <!-- <div class="header__doc-list_item" @click="openReportSelectByFuelCards">
              Отчёт по топливным картам
            </div> -->
            <!-- <div class="header__doc-list_item" @click="reportFuelBalance">
              Отчет по балансу водителей
            </div> -->
            <div class="header__doc-list_item" @click="reportTerminalFuel">
              Отчёт по топливораздаче
            </div>
            <!-- <div class="header__doc-list_item" @click="reportTerminalInFuel">
              Отчёт по пополнениям топлива
            </div> -->
            <div class="header__doc-list_item" @click="reportTerminalOutFuel">
              Отчёт по остаткам топлива
            </div>
          </div>
        </div>
      </div>
      <div class="header__router">
        <div class="header__route" @click="redirectTo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            style=""
          >
            <rect
              id="backgroundrect"
              width="100%"
              height="100%"
              x="0"
              y="0"
              fill="none"
              stroke="none"
            />
            <rect
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="7.5"
              fill="white"
              class="svg_rect1"
            />
            <path
              d="M23.846938610076904,14.275530311126708 H27.840738610076905 C27.990138610076905,14.27455031112671 28.136338610076905,14.318800311126708 28.260138610076904,14.40246031112671 C28.383938610076903,14.486120311126708 28.479538610076904,14.605280311126709 28.534438610076904,14.744280311126708 L29.846938610076904,18.02551031112671 "
              stroke="#777C9A"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
              id="svg_2"
              class=""
            />
            <path
              d="M24.971938610076904,27.02551031112671 C26.214538610076904,27.02551031112671 27.221938610076904,26.01811031112671 27.221938610076904,24.77551031112671 C27.221938610076904,23.53291031112671 26.214538610076904,22.52551031112671 24.971938610076904,22.52551031112671 C23.729338610076905,22.52551031112671 22.721938610076904,23.53291031112671 22.721938610076904,24.77551031112671 C22.721938610076904,26.01811031112671 23.729338610076905,27.02551031112671 24.971938610076904,27.02551031112671 z"
              stroke="#777C9A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              id="svg_3"
              class=""
            />
            <path
              d="M13.721938610076904,27.02551031112671 C14.964578610076904,27.02551031112671 15.971938610076904,26.01811031112671 15.971938610076904,24.77551031112671 C15.971938610076904,23.53291031112671 14.964578610076904,22.52551031112671 13.721938610076904,22.52551031112671 C12.479298610076905,22.52551031112671 11.471938610076904,23.53291031112671 11.471938610076904,24.77551031112671 C11.471938610076904,26.01811031112671 12.479298610076905,27.02551031112671 13.721938610076904,27.02551031112671 z"
              stroke="#777C9A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              id="svg_4"
              class=""
            />
            <path
              d="M22.721938610076904,24.77551031112671 H15.971938610076904 "
              stroke="#777C9A"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
              id="svg_5"
              class=""
            />
            <path
              d="M11.471938610076904,24.77551031112671 H9.596938610076904 C9.398028610076905,24.77551031112671 9.207258610076904,24.69651031112671 9.066608610076905,24.55581031112671 C8.925958610076904,24.41521031112671 8.846938610076904,24.22441031112671 8.846938610076904,24.02551031112671 V13.525510311126709 C8.846938610076904,13.32660031112671 8.925958610076904,13.135830311126709 9.066608610076905,12.99518031112671 C9.207258610076904,12.854530311126709 9.398028610076905,12.775510311126709 9.596938610076904,12.775510311126709 H23.846938610076904 V22.82551031112671 "
              stroke="#777C9A"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
              id="svg_6"
              class=""
            />
            <path
              d="M23.846938610076904,18.02551031112671 H29.846938610076904 V24.02551031112671 C29.846938610076904,24.22441031112671 29.767938610076904,24.41521031112671 29.627238610076905,24.55581031112671 C29.486638610076906,24.69651031112671 29.295838610076906,24.77551031112671 29.096938610076904,24.77551031112671 H27.221938610076904 "
              stroke="#777C9A"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
              id="svg_7"
              class=""
            />
            <rect
              x="0.5816327333450317"
              y="0.5"
              width="39"
              height="39"
              rx="7.5"
              stroke="#E1E3ED"
              fill="transparent"
              id="svg_8"
              class=""
            />
          </svg>
          <HoverTooltip :text="'Перейти в мониторинг'" />
        </div>
      </div>
      <div class="header__logout">
        <div
          class="header__logout-button pointer"
          :class="{ 'logout-active': !$store.state.fetchCookies.login }"
          @click="logoutAutUser"
        >
          <svg
            class="header__logout-button_img"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              class="first-rect"
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="7.5"
              fill="white"
            />
            <g clip-path="url(#clip0_377_3752)">
              <path
                d="M23.3571 25.7042V26.2857C23.3571 26.5888 23.2367 26.8795 23.0224 27.0938C22.8081 27.3082 22.5174 27.4286 22.2143 27.4286H14.2143C13.9112 27.4286 13.6205 27.3082 13.4061 27.0938C13.1918 26.8795 13.0714 26.5888 13.0714 26.2857V13.7143C13.0714 13.4112 13.1918 13.1205 13.4061 12.9062C13.6205 12.6918 13.9112 12.5714 14.2143 12.5714H22.2143C22.5174 12.5714 22.8081 12.6918 23.0224 12.9062C23.2367 13.1205 23.3571 13.4112 23.3571 13.7143V14.5"
                stroke="#777C9A"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 20L27 20"
                stroke="#777C9A"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M25.0714 17.7143L27.3571 20L25.0714 22.2857"
                stroke="#777C9A"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <rect
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="7.5"
              stroke="#E1E3ED"
            />
            <defs>
              <clipPath id="clip0_377_3752">
                <rect
                  width="17"
                  height="17"
                  fill="white"
                  transform="translate(11.5 11.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <HoverTooltip :text="'Выход'" />
        </div>
      </div>
    </div>
    <div class="header__modalSelect" v-if="$store.state.isVisibleReportSelect">
      <ModalReportSelect @reportCardCards="reportCardCards" />
    </div>
    <div
      class="header__modalSelect"
      v-if="$store.state.isVisibleReportSelectByFuelCards"
    >
      <ModalReportSelectByFuelCards @reportFuelCards="reportFuelCards" />
    </div>
  </div>
  <ModalReportErrorTerminals
    v-if="modalReportErrorTerminals"
    @closeModalReportlError="closeModalReportlError"
  />
  <ModalReportErrorCards
    v-if="modalReportErrorCards"
    @closeModalReportlError="closeModalReportlError"
  />
</template>
<script>
import config from "/config.json";
import {
  ref,
  getCurrentInstance,
  computed,
  defineAsyncComponent,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/styles/uiStyles/datePicker.scss";
import { useLogin } from "@/composition/useLogin.js";
import { useReport } from "@/composition/useReport.js";
import { useRouter } from "vue-router";
import ModalReportSelect from "@/components/Modals/ModalReportSelect.vue";
import ModalReportSelectByFuelCards from "@/components/Modals/ModalReportSelectByFuelCards.vue";
import ModalReportErrorCards from "@/components/Modals/ModalReportErrorCards.vue";
import ModalReportErrorTerminals from "@/components/Modals/ModalReportErrorTerminals.vue";
import GrayCover from "@/components/UI/coversForModals/GrayCoverVue.vue";
import HoverTooltip from "@/components/UI/HoverTooltip.vue";
const TimePicker = defineAsyncComponent(() =>
  import("@/components/UI/TimePickerCustom.vue"),
);
export default {
  components: {
    Datepicker,
    ModalReportSelect,
    ModalReportSelectByFuelCards,
    ModalReportErrorCards,
    ModalReportErrorTerminals,
    GrayCover,
    HoverTooltip,
  },
  emits: ["toggleBurgerMenu"],
  setup() {
    const store = useStore();
    const timePicker = computed(() => TimePicker);
    const router = useRouter();
    const { logoutUser } = useLogin();
    const dpIsOpened = ref(false); // состояние датапикера
    const teleportCenter = computed(
      () => window.innerWidth < 600 || window.innerHeight < 600,
    ); // если просмотр на мобильных телефонах datepicker делаем посередине экрана
    const {
      getReportCards,
      getReportFuelCards,
      getReportBalance,
      getReportTerminalFuel,
      getReportTerminalInFuel,
      getReportTerminalOutFuel,
      report,
    } = useReport();
    const logoutAutUser = () => {
      store.commit("setLogin", false);
      logoutUser(store.state.fetchCookies.params).then(() => {
        store.commit("clearTokenCSRF");
        window.location.href = config.AUTH_URL;
      });
    };
    let app = getCurrentInstance();
    const dateB = ref(new Date(new Date().setHours(0, 0, 0, 0)));
    const dateF = ref(new Date(new Date().setHours(23, 59, 0, 0)));
    const docList = ref(false);
    const reportCard = () => {
      docList.value = store.state.isVisibleReportList;
      docList.value = !docList.value;
      store.commit("setVisibleReportList", docList.value);
    };
    const modalReportErrorCards = ref(false);
    const modalReportErrorTerminals = ref(false);
    const closeModalReportlError = () => {
      modalReportErrorCards.value = false;
      modalReportErrorTerminals.value = false;
    };

    const openReportSelect = () => [
      store.commit("setVisibleReportSelect", true),
    ];

    const openReportSelectByFuelCards = () => [
      store.commit("setVisibleReportSelectByFuelCards", true),
    ];

    const reportFuelCards = data => {
      if (!dateB.value) {
        dateB.value = new Date(new Date().setHours(0, 0, 0, 0));
      }
      if (!dateF.value) {
        dateF.value = new Date(new Date().setHours(23, 59, 0, 0));
      }
      //сохранение данных для кнопки "обновление"
      store.commit("setReportDataSelect", data);
      app.appContext.config.globalProperties.$report = null;
      app.appContext.config.globalProperties.$toggles = [];
      app.appContext.config.globalProperties.$card_id = [true];

      router.push({
        name: "Report",
      });

      docList.value = false;
      getReportFuelCards(
        Number(dateB.value),
        Number(dateF.value),
        store.state.fetchCookies.params,
        store.state.reportDataSelect,
      ).then(() => {
        store.commit("setReport", JSON.parse(JSON.stringify(report.value)));
        app.appContext.config.globalProperties.$report = report.value;
        app.appContext.config.globalProperties.$reportName =
          "getReportFuelCards";
        app.appContext.config.globalProperties.$reportStartTime = Number(
          dateB.value,
        );
        app.appContext.config.globalProperties.$reportEndTime = Number(
          dateF.value,
        );
      });
    };

    const reportCardCards = data => {
      if (!dateB.value) {
        dateB.value = new Date(new Date().setHours(0, 0, 0, 0));
      }
      if (!dateF.value) {
        dateF.value = new Date(new Date().setHours(23, 59, 0, 0));
      }
      if (app.appContext.config.globalProperties.$card_id.length < 1) {
        modalReportErrorCards.value = true;
        return;
      }
      //сохранение данных для кнопки "обновление"
      store.commit("setReportDataSelect", data);
      app.appContext.config.globalProperties.$report = null;
      app.appContext.config.globalProperties.$toggles = [];

      router.push({
        name: "Report",
      });

      docList.value = false;
      getReportCards(
        app.appContext.config.globalProperties.$card_id,
        Number(dateB.value),
        Number(dateF.value),
        store.state.fetchCookies.params,
        store.state.reportDataSelect,
      ).then(() => {
        store.commit("setReport", JSON.parse(JSON.stringify(report.value)));
        app.appContext.config.globalProperties.$report = report.value;
        app.appContext.config.globalProperties.$reportName = "getReportCards";
        app.appContext.config.globalProperties.$reportStartTime = Number(
          dateB.value,
        );
        app.appContext.config.globalProperties.$reportEndTime = Number(
          dateF.value,
        );
      });
    };

    const reportFuelBalance = () => {
      if (!dateB.value) {
        dateB.value = new Date(new Date().setHours(0, 0, 0, 0));
      }
      if (!dateF.value) {
        dateF.value = new Date(new Date().setHours(23, 59, 0, 0));
      }
      if (app.appContext.config.globalProperties.$card_id.length < 1) {
        modalReportErrorCards.value = true;
        return;
      }
      app.appContext.config.globalProperties.$report = null;
      app.appContext.config.globalProperties.$toggles = [];
      router.push({
        name: "Report",
      });
      store.commit("setInputLiter", 0);
      docList.value = false;
      getReportBalance(
        app.appContext.config.globalProperties.$card_id,
        Number(dateB.value),
        Number(dateF.value),
        store.state.fetchCookies.params,
      ).then(() => {
        store.commit("setReport", JSON.parse(JSON.stringify(report.value)));
        app.appContext.config.globalProperties.$report = report.value;
        app.appContext.config.globalProperties.$reportName = "getReportBalance";
        app.appContext.config.globalProperties.$reportStartTime = Number(
          dateB.value,
        );
        app.appContext.config.globalProperties.$reportEndTime = Number(
          dateF.value,
        );
      });
    };

    const reportTerminalFuel = () => {
      if (!dateB.value) {
        dateB.value = new Date(new Date().setHours(0, 0, 0, 0));
      }
      if (!dateF.value) {
        dateF.value = new Date(new Date().setHours(23, 59, 0, 0));
      }
      if (app.appContext.config.globalProperties.$terminal_id < 1) {
        modalReportErrorTerminals.value = true;
        return;
      }
      app.appContext.config.globalProperties.$report = null;
      app.appContext.config.globalProperties.$toggles = [];
      router.push({
        name: "Report",
      });

      docList.value = false;
      getReportTerminalFuel(
        app.appContext.config.globalProperties.$terminal_id,
        Number(dateB.value),
        Number(dateF.value),
        store.state.fetchCookies.params,
      ).then(() => {
        store.commit("setReport", JSON.parse(JSON.stringify(report.value)));
        store.commit(
          "setReportData",
          JSON.parse(JSON.stringify(report.value.calculator)),
        );
        app.appContext.config.globalProperties.$report = report.value;
        app.appContext.config.globalProperties.$reportName =
          "reportTerminalFuel";
        app.appContext.config.globalProperties.$reportStartTime = Number(
          dateB.value,
        );
        app.appContext.config.globalProperties.$reportEndTime = Number(
          dateF.value,
        );
      });
    };

    const reportTerminalInFuel = () => {
      if (!dateB.value) {
        dateB.value = new Date(new Date().setHours(0, 0, 0, 0));
      }
      if (!dateF.value) {
        dateF.value = new Date(new Date().setHours(23, 59, 0, 0));
      }
      if (app.appContext.config.globalProperties.$terminal_id < 1) {
        modalReportErrorTerminals.value = true;
        return;
      }
      app.appContext.config.globalProperties.$report = null;
      app.appContext.config.globalProperties.$toggles = [];
      router.push({
        name: "Report",
      });

      docList.value = false;
      getReportTerminalInFuel(
        app.appContext.config.globalProperties.$terminal_id,
        Number(dateB.value),
        Number(dateF.value),
        store.state.fetchCookies.params,
      ).then(() => {
        store.commit("setReport", JSON.parse(JSON.stringify(report.value)));
        app.appContext.config.globalProperties.$report = report.value;
        app.appContext.config.globalProperties.$reportName =
          "getReportTerminalInFuel";
        app.appContext.config.globalProperties.$reportStartTime = Number(
          dateB.value,
        );
        app.appContext.config.globalProperties.$reportEndTime = Number(
          dateF.value,
        );
      });
    };

    const reportTerminalOutFuel = () => {
      if (!dateB.value) {
        dateB.value = new Date(new Date().setHours(0, 0, 0, 0));
      }
      if (!dateF.value) {
        dateF.value = new Date(new Date().setHours(23, 59, 0, 0));
      }
      if (app.appContext.config.globalProperties.$terminal_id < 1) {
        modalReportErrorTerminals.value = true;
        return;
      }
      app.appContext.config.globalProperties.$report = null;
      router.push({
        name: "Report",
      });
      docList.value = false;
      getReportTerminalOutFuel(
        app.appContext.config.globalProperties.$terminal_id,
        Number(dateB.value),
        Number(dateF.value),
      ).then(() => {
        store.commit("setReport", JSON.parse(JSON.stringify(report.value)));
        app.appContext.config.globalProperties.$report = report.value;
        app.appContext.config.globalProperties.$reportName =
          "getReportTerminalOutFuel";
        app.appContext.config.globalProperties.$reportStartTime = Number(
          dateB.value,
        );
        app.appContext.config.globalProperties.$reportEndTime = Number(
          dateF.value,
        );
      });
    };
    function redirectTo() {
      window.location.href = config.TRACK_URL;
    }
    onMounted(() => {
      // запрет на появление стандартной андроид клавиатуры
      const inputDP1 = document.querySelector(".header__date-begin .dp__input");
      const inputDP2 = document.querySelector(
        ".header__date-finish .dp__input",
      );
      inputDP1.addEventListener("click", () => {
        inputDP1.blur();
      });
      inputDP1.addEventListener("focus", () => {
        inputDP1.blur();
      });
      inputDP2.addEventListener("click", () => {
        inputDP2.blur();
      });
      inputDP2.addEventListener("focus", () => {
        inputDP2.blur();
      });
    });
    const openDatePicker = () => {
      dpIsOpened.value = true;
    };
    const closedDatePicker = () => {
      dpIsOpened.value = false;
    };
    return {
      timePicker,
      logoutAutUser,
      reportCard,
      report,
      docList,
      openReportSelect,
      openReportSelectByFuelCards,

      reportFuelCards,
      reportCardCards,
      reportFuelBalance,
      reportTerminalFuel,
      reportTerminalInFuel,
      reportTerminalOutFuel,
      dateB,
      dateF,
      app,
      modalReportErrorCards,
      modalReportErrorTerminals,
      closeModalReportlError,
      redirectTo,
      dpIsOpened,
      teleportCenter,
      openDatePicker,
      closedDatePicker,
    };
  },
};
</script>
<style lang="scss" src="../styles/header.scss"></style>
