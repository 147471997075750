import { useApi } from "@/composition/useApi.js";

export default {
  state: () => ({
    login: true,

    isClear: true,
    isLoadingDataGraph: true,
    isPreloaderGraphsStart: true,
    isPreloaderGraphs: true,
    titleTerminal: null,
    terminalIcon: null,

    isHeightBlock: false,
    isActiveTokenCSRF: false,
    tokenCSRF: null,
    terminalStatistic: null,
    terminal: null,
    params: {},
  }),
  mutations: {
    setLogin(state, bool) {
      state.login = bool;
    },
    setLoadingDataGraph(state, bool) {
      state.isLoadingDataGraph = bool;
    },
    setPreloaderGraphsStart(state, bool) {
      state.isPreloaderGraphsStart = bool;
    },
    setPreloaderGraphs(state, bool) {
      state.isPreloaderGraphs = bool;
    },
    setTitleTerminal(state, title) {
      state.titleTerminal = title;
    },
    setTerminalIcon(state, iconInfo) {
      state.terminalIcon = { icon: iconInfo[0], isOnline: iconInfo[1] };
    },
    setHeightBlock(state, bool) {
      state.isHeightBlock = bool;
    },
    setTerminal(state, data) {
      state.terminal = data;
    },
    setTerminalStatistic(state, data) {
      state.terminalStatistic = data;
    },
    setActiveTokenCSRF(state, boolean) {
      state.isActiveTokenCSRF = boolean;
    },
    setTokenCSRF(state, token) {
      state.tokenCSRF = token;
    },
    clearTokenCSRF(state) {
      state.tokenCSRF = null;
    },
    setParams(state, token) {
      state.params = {
        withCredentials: true,
        headers: {
          "csrf-token": token,
        },
      };
    },
  },
  actions: {
    sendRequestCookies({ commit }) {
      //Удалить
      commit("setTokenCSRF", "tempValue");
      commit("setParams", "tempValue");
      localStorage.setItem("scrf", "tempValue");
      commit("setActiveTokenCSRF", true);
    },
    sendRequestTerminalStatistic({ state, commit }, id) {
      //Адоптировать под текущую реализацию
      const { api } = useApi();
      return api.get(`/terminals/${id}/graph`).then(r => {
        let oldData = {};
        if (r.status == 200) {
          // если нет значения maxFuel, уровень топлива делаем 100%
          for (const property in r.data.realtime) {
            if (!r.data.realtime[property].maxFuel)
              r.data.realtime[property].maxFuel = r.data.realtime[property].f;
          }

          oldData = r.data;
          oldData.fuelStatistic = r.data.statistic;
          oldData.realtimeTankers = r.data.realtime;
          oldData.tanker = r.data.total;
          oldData.fuelStatistic = oldData.fuelStatistic.map(item => {
            return { tankers: item.sensors, time: item.time };
          });

          commit("setTerminalStatistic", {
            data: oldData,
            event: "printDashboardData",
          });

          commit("setHeightBlock", true);
          commit("setLoadingDataGraph", true);
        }
      });
    },
    sendRequestTerminal({ state, commit }, id) {
      //Адоптировать под текущую реализацию
      const { api } = useApi();
      return api.get(`/terminals/${id}`).then(r => {
        if (r.status == 200) {
          commit("setTerminal", {
            data: r.data,
            event: "printDashboardData",
          });
          commit("setTitleTerminal", r.data.name);
          commit("setTerminalIcon", [
            r.data.terminalGroup.icon.toLowerCase(),
            r.data.isOnline,
          ]);
        }
      });
    },
  },
};
