<template>
  <div class="modal-terminal-error">
    <div class="modal-terminal-error-body">
      <div class="modal-terminal-error-body__text">
        Не выбран ни один терминал
      </div>
      <div class="modal-terminal-error-body__buttons">
        <div
          class="modal-terminal-error-body__buttons_accept"
          @click="closeModalReportlError"
        >
          OK
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup(_props, { emit }) {
    const closeModalReportlError = () => {
      emit("closeModalReportlError");
    };
    return {
      closeModalReportlError,
    };
  },
};
</script>
<style lang="scss" scoped>
.modal-terminal-error {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #08163ab8;
  width: 100%;
  height: 100%;
  z-index: 8;
}
.modal-terminal-error-body {
  background: #ffffff;
  box-shadow: 0px 8px 8px rgba(47, 50, 66, 0.04),
    0px 12px 28px rgba(47, 50, 66, 0.12);
  border-radius: 8px;
  width: 350px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  &__text {
    color: #35384a;
    font-size: 24px;
    font-weight: 600;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    &_accept {
      color: #f6f7fb;
      background: #da2e35;
      border: 1px solid #da2e35;
      line-height: 40px;
      padding: 0px 42.5px;
      border-radius: 8px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
@media (max-width: 480px) {
  .modal-terminal-error-body {
    width: 96%;
    &__text {
      font-size: 1.2rem;
      padding-top: 20px;
    }
    &__buttons {
      width: 50%;
      font-size: 0.9rem;
      margin: 20px 0;
    }
    &__buttons_accept {
      margin: 0;
      width: 100%;
    }
  }
}
</style>
