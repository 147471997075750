<template>
  <div class="modal-reportSelect">
    <div class="modal-reportSelect__body">
      <div class="modal-reportSelect__content">
        <div class="modal-reportSelect__title">
          Отчет по топливораздаче - водители
        </div>

        <div class="modal-inputInfo modal-reportSelect__difference">
          <label for="reportSelect__difference"
            >Максимальная разница в %:</label
          >
          <input
            class="modal-reportSelect__difference-input"
            type="number"
            id="reportSelect__difference"
          />
        </div>
        <div class="modal-inputInfo modal-reportSelect__violation">
          <input
            class="modal-reportSelect__violation-input"
            type="checkbox"
            id="reportSelect__violation"
            ref="checkboxViolation"
            :class="{ 'checkbox--active': isViolation }"
            @click="activeCheckbox"
          />
          <label for="reportSelect__violation"
            >Показывать только с нарушениями</label
          >
        </div>
        <div class="modal-reportSelect__buttons">
          <div class="modal-reportSelect__cancel" @click="closeModal">
            <button>Отмена</button>
          </div>
          <div class="modal-reportSelect__compose" @click="submitModal">
            <button>Составить</button>
          </div>
        </div>
        <div class="modal-reportSelect__error" v-if="isErrorDifference">
          Укажите максимальную разницу
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
export default {
  name: "reportSelect",
  setup(props, { emit }) {
    const store = useStore();

    const isViolation = ref(false);
    const isErrorDifference = ref(false);

    const activeCheckbox = () => {
      isViolation.value = !isViolation.value;
    };

    const closeModal = () => {
      store.commit("setVisibleReportSelect", false);
    };

    const submitModal = () => {
      let isOnlyDiscrepancy = document.querySelector(
        ".modal-reportSelect__violation-input",
      );
      let minDiscrepancy = document.querySelector(
        ".modal-reportSelect__difference-input",
      );
      let data = {
        isOnlyDiscrepancy: isOnlyDiscrepancy.classList.contains(
          "checkbox--active",
        )
          ? true
          : false,
        minDiscrepancy: parseInt(minDiscrepancy.value),
      };
      if (minDiscrepancy.value.length) {
        emit("reportCardCards", data);
        store.commit("setVisibleReportSelect", false);
      } else {
        isErrorDifference.value = true;
        setTimeout(() => {
          isErrorDifference.value = false;
        }, 3500);
      }
    };

    onMounted(() => {
      document.querySelector(
        ".modal-reportSelect__difference-input",
      ).value = 10;
    });
    return {
      isErrorDifference,
      activeCheckbox,
      isViolation,
      closeModal,
      submitModal,
    };
  },
};
</script>
<style lang="scss" scoped>
.modal {
  &-inputInfo {
    margin-bottom: 20px;
  }
  &-reportSelect {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #08163ab8;
    width: 100%;
    height: 100%;
    z-index: 8;
    &__body {
      background: #ffffff;
      box-shadow: 0px 8px 8px rgba(47, 50, 66, 0.04),
        0px 12px 28px rgba(47, 50, 66, 0.12);
      border-radius: 8px;
      width: 450px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;
    }
    &__content {
      position: relative;
      width: 100%;
      text-align: start;
    }
    &__title {
      margin-bottom: 30px;
      color: #35384a;
      font-size: 16px;
      font-weight: 600;
    }
    &__difference {
      margin-bottom: 30px;
      & input {
        padding: 10px 11px;
        background: #f6f7fb;
        border: 1px solid #d5d9ed;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        margin-top: 5px;
        font-size: 16px;
        color: #6b7298;
        &:checked {
          background: red;
        }
      }
      & input:focus {
        outline: none;
        box-shadow: 0px 0px 0px 2px #80808029;
      }
    }
    &__difference > label {
      display: block;
      margin-bottom: 10px;
    }
    &__violation {
      position: relative;
      width: 100%;
      height: 100%;
      margin-bottom: 30px;
      & input {
        appearance: none;
        position: absolute;
        top: 0;
        left: 0;
      }
      & label {
        margin-left: 32px;
        cursor: pointer;
        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          top: calc(50% - 10px);
          left: 0;
          width: 18px;
          height: 18px;
          background: #ffffff;
          border: 1px solid #9e9e9e;
          border-radius: 4px;
          transition: all 0.2s ease;
        }
        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 4px);
          left: 7px;
          display: block;
          width: 9px;
          height: 9px;
          background: url("../../assets/checkbox.svg") no-repeat center;
          background-size: cover;
          z-index: 2;
        }
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & button {
        font-size: 16px;
      }
    }
    &__compose button {
      color: #f6f7fb;
      background: #da2e35;
      border: 1px solid #da2e35;
      line-height: 40px;
      padding: 0px 42.5px;
      border-radius: 8px;
      transition: all 0.2s ease;
      cursor: pointer;
      &:hover {
        background: #af141a;
      }
    }
    &__cancel button {
      border: 1px solid #d5d9ed;
      background-color: #fff;
      color: #6b7298;
      line-height: 40px;
      padding: 0px 42.5px;
      border-radius: 8px;
      transition: all 0.2s ease;
      cursor: pointer;
      &:hover {
        background: #777c9a;
        color: #fff;
      }
    }
    &__error {
      position: absolute;
      top: -100px;
      left: -16px;
      text-align: center;
      display: inline-block;
      width: 100%;
      color: #842029;
      background-color: #f8d7da;
      border-color: #f5c2c7;
      padding: 1rem;
      border-radius: 6px;
    }
  }
}
.checkbox--active + label:after {
  background: #36b270;
}
@media (max-width: 480px) {
  .modal {
    &-reportSelect {
      &__body {
        padding: 20px;
        width: 96%;
      }
      &__compose,
      &__cancel {
        width: 45%;
        button {
          padding: 0;
          width: 100%;
        }
      }
      &__violation {
        font-size: 0.9rem;
      }
    }
  }
}
</style>
